const d3 = require('d3');
const Utils = require('./utils');

module.exports = (data, options = {}) => {

  if (!document.getElementById('dataTable')) {
    return;
  }

  const defaultOptions = {
    maxCost: 3000
  };

  options = Object.assign(defaultOptions, options);

  d3.selectAll('.data table').remove();

  const table = d3.select('.data').append('table')
  const thead = table.append('thead')
  const tbody = table.append('tbody');
  const columns = Object.keys(data[0]);

  // append the header row
  thead.append('tr')
    .selectAll('th')
    .data(columns).enter()
    .append('th')
    .text(function (column) { return column; });

  // create a row for each object in the data
  var rows = tbody.selectAll('tr')
    .data(data)
    .enter()
    .append('tr');

  // create a cell in each row for each column
  var cells = rows.selectAll('td')
    .data(function (row) {
      return columns.map(function (column) {
        return {column: column, value: Utils.formatNumber(row[column])};
      });
    })
    .enter()
    .append('td')
    .text(function (d) { return d.value; });

};
