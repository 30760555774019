const swrtcCost = (personMinutes, plan, options = {}) => {
  const mbPerCall = options.streamBitRate / 8; //megabytes per second
  const callSize = options.averageCallsize;
  const swrtc = options.plans[plan];
  // other companies charge per personMinute but we just need minutes for our calculation
  const minutes = personMinutes / callSize;
  // number of streams = callSize * (callSize - 1)
  let bandwidth = minutes * callSize * (callSize - 1) * mbPerCall * 60 * options.turnPerc;
  bandwidth -= swrtc.data * 1000;
  let bandwidthCost = 0;
  if (bandwidth < 0) {
    bandwidth = 0;
  } else {
    bandwidthCost = bandwidth / 1000 * swrtc.gbrate;
  }
  const cost = swrtc.cost + bandwidthCost;
  return cost;
}

const twilioCost = (personMinutes, options = { }) => {
  return personMinutes * .004;
}

const tokboxCost = (personMinutes, options = { }) => {
  const callSize = options.averageCallsize;
  const streams = callSize * (callSize - 1);
  let minutes = personMinutes / callSize;
  minutes -= 2000;
  if (minutes < 0) minutes = 0;
  return minutes * streams * .00475 + 20;
}

const generateTable = (options) => {

  const costTable= [];

  for (let personMinutes = 0; personMinutes <= options.maxPersonMinutes; personMinutes += options.maxPersonMinutes / 50) {
    const row = {
      personMinutes,
      swrtcStartup: swrtcCost(personMinutes, 'startup', options),
      swrtcPro: swrtcCost(personMinutes, 'pro', options),
      swrtcPremium: swrtcCost(personMinutes, 'premium', options),
    };

    if (options.competition) {
      row.twilio = twilioCost(personMinutes, options);
      row.tokbox =  tokboxCost(personMinutes, options);
    }
    costTable.push(row);
  }

  return costTable;
};

module.exports = {
  generateTable,
  twilioCost,
  tokboxCost,
  swrtcCost
};
