const formatNumber = (value, showDecimal) => {
  showDecimal = !!showDecimal;
  parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2);
  let [whole, decimal] = value.toString().split('.');
  const out = whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (showDecimal) {
    if (!decimal) decimal = '00'
    while (decimal.length < 2) {
      decimal += '0';
    }
    decimal = decimal.substr(0, 2);
    return `${out}.${decimal}`;
  }
  return out;
};

module.exports = {
  formatNumber
};
