const d3 = require('d3');
const GenerateData = require('./data');
const DrawGraph = require('./graph');
const DrawTable = require('./table');
const defaults = require('./options.json');
const Utils = require('./utils');

const getNumberFromInput = (input) => {
  let value = String(input.value).replace(/[^\d.]/g,'');
  value = parseFloat(value);
  if (Number.isNaN(value)) {
    value = 1;
  }
  input.value = value;
  return value;
}

const getOptions = () => {
  const inputDiv = document.getElementById('input-form');
  const inputs = inputDiv.getElementsByTagName('input');
  let options = {};
  for (const input of inputs) {
    const value = getNumberFromInput(input);
    if (input.id.indexOf('.') !== -1) {
      parts = input.id.split('.');
      lastObj = options;
      for (let idx = 0; idx < parts.length; idx++) {
        if (idx < parts.length - 1) {
          if (!lastObj.hasOwnProperty(parts[idx])) {
            lastObj[parts[idx]] = {};
          }
          lastObj = lastObj[parts[idx]];
        } else {
          lastObj[parts[idx]] = value;
        }
      }
    } else {
      options[input.id] = value;
    }
  }
  const defaultOptions = { ...defaults };
  options = Object.assign(defaultOptions, options);

  options.averageCallsize = options.averageCallsize / 10 + 2;
  options.turnPerc = options.turnPerc / 100;
  options.personMinutes = options.users * options.weekSessions * 4.3 * options.sessionMinutes;

  options.maxPersonMinutes = Math.max(200000, Math.ceil(options.personMinutes / 10000) * 10000 + 50000);

  options.competition = window.includeCompetition;

  return options;
};

const update = () => {
  const options = getOptions();

  const data = GenerateData.generateTable(options);
  const max = Math.max(Math.round(data[data.length - 1].swrtcStartup / 1000) * 1000, 3000);
  options.maxCost = max;

  const personMinutes = options.personMinutes;
  const twilio = Utils.formatNumber(GenerateData.twilioCost(personMinutes, options));
  const tokbox = Utils.formatNumber(GenerateData.tokboxCost(personMinutes, options));
  const startup = Utils.formatNumber(GenerateData.swrtcCost(personMinutes, 'startup', options));
  const pro = Utils.formatNumber(GenerateData.swrtcCost(personMinutes, 'pro', options));
  const premium = Utils.formatNumber(GenerateData.swrtcCost(personMinutes, 'premium', options));

  const answer = [];
  if (options.competition) {
    answer.push({
      minutes: options.personMinutes,
      Startup: startup,
      Pro: pro,
      Premium: premium,
      TokBox: tokbox,
      Twilio: twilio
    });
  } else {
    answer.push({
      minutes: options.personMinutes,
      Startup: startup,
      Pro: pro,
      Premium: premium
    });
  }

  DrawGraph(data, options);
  DrawTable(data, options);
  DrawAnswer(answer, options);
}

const updateButton = document.getElementById('update-button');
if (updateButton) {
  updateButton.addEventListener('click', (e) => {
    e.preventDefault();
    update();
  });
}

const inputDiv = document.getElementById('input-form');
const inputs = inputDiv.getElementsByTagName('input');
for (const input of inputs) {
  input.addEventListener('keypress', (e) => {
    if (!e) e = window.event;
    var keyCode = e.keyCode || e.which;
    if (keyCode == '13'){
      update();
      return false;
    }
  });
}

const callSlider = document.getElementById('averageCallsize');
if (callSlider) {
  callSlider.addEventListener('input', (e) => {
    const callSize = callSlider.value / 10 + 2;
    const callLabel = document.getElementById('callSizeLabel');
    callLabel.firstChild.nodeValue = `Avg Call Size: ${callSize}`;
  });

  callSlider.addEventListener('change', (e) => {
    update();
  });
}

const turnSlider = document.getElementById('turnPerc');
if (turnSlider) {
  turnSlider.addEventListener('input', (e) => {
    const turnPerc = turnSlider.value;
    const turnLabel = document.getElementById('turnPercLabel');
    turnLabel.firstChild.nodeValue = `TURN Percentage: ${turnPerc}%`;
  });

  turnSlider.addEventListener('change', (e) => {
    update();
  });
}

const DrawAnswer = (data, options) => {

  d3.selectAll('.output table').remove();
  const table = d3.select('.output').append('table')
  const thead = table.append('thead')
  const tbody = table.append('tbody');
  const columns = Object.keys(data[0]);

  // append the header row
  thead.append('tr')
    .selectAll('th')
    .data(columns).enter()
    .append('th')
    .text(function (column) { return column; });

  // create a row for each object in the data
  var rows = tbody.selectAll('tr')
    .data(data)
    .enter()
    .append('tr');

  // create a cell in each row for each column
  var cells = rows.selectAll('td')
    .data(function (row) {
      return columns.map(function (column) {
        if (column === 'minutes') {
          return {column: column, value: Utils.formatNumber(row[column])};
        }
          return {column: column, value: `$${Utils.formatNumber(row[column], true)}`};
      });
    })
    .enter()
    .append('td')
    .text(function (d) { return d.value; });
};

window.generatePage = update;
