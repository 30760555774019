const d3 = require('d3');

module.exports = (data, options = {}) => {

  d3.selectAll('.graph svg').remove();

  const margin = {top: 20, right: 50, bottom: 50, left: 50}
  const width = 650;
  const height = 400;

  const xScale = d3.scaleLinear()
    .domain([0, data[data.length - 1].personMinutes])
    .range([0, width]);

  const yScale = d3.scaleLinear()
    .domain([0, options.maxCost])
    .range([height, 0]);

  const lineStartup = d3.line()
    .x(function(d, i) {
      return xScale(d.personMinutes);
    })
    .y(function(d, i) {
      const y = yScale(d.swrtcStartup);
      if (y < 0) {
        return undefined;
      }
      return y;
    })
    .curve(d3.curveMonotoneX);

  const linePro = d3.line()
    .x(function(d, i) {
      return xScale(d.personMinutes);
    }) // set the x values for the line generator
    .y(function(d, i) {
      const y = yScale(d.swrtcPro);
      if (y < 0) {
        return undefined;
      }
      return y;
    }) // set the y values for the line generator
    .curve(d3.curveMonotoneX) // apply smoothing to the line

  const linePremium = d3.line()
      .x(function(d, i) {
        return xScale(d.personMinutes);
      }) // set the x values for the line generator
      .y(function(d, i) {
        const y = yScale(d.swrtcPremium);
        if (y < 0) {
          return undefined;
        }
        return y;
      }) // set the y values for the line generator
      .curve(d3.curveMonotoneX) // apply smoothing to the line

  const lineTwilio = d3.line()
      .x(function(d, i) {
        return xScale(d.personMinutes);
      }) // set the x values for the line generator
      .y(function(d, i) {
        const y = yScale(d.twilio);
        if (y < 0) {
          return undefined;
        }
        return y;
      }) // set the y values for the line generator
      .curve(d3.curveMonotoneX) // apply smoothing to the line

  const lineTokbox = d3.line()
      .x(function(d, i) {
        return xScale(d.personMinutes);
      }) // set the x values for the line generator
      .y(function(d, i) {
        const y = yScale(d.tokbox);
        if (y < 0) {
          return undefined;
        }
        return y;
      }) // set the y values for the line generator
      .curve(d3.curveMonotoneX) // apply smoothing to the line

  // 1. Add the SVG to the page and employ #2
  const svg = d3.select(".graph").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(xScale)) // Create an axis component with d3.axisBottom

  svg.append("g")
    .attr("class", "y axis")
    .call(d3.axisLeft(yScale)); // Create an axis component with d3.axisLeft

  svg.append("line")
    .attr("x1", xScale(options.personMinutes))
    .attr("y1", yScale(0))
    .attr("x2", xScale(options.personMinutes))
    .attr("y2", yScale(options.maxCost))
    .attr("style", "stroke:rgb(250, 218, 94);stroke-width:4")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 0 - margin.left -5)
    .attr("x",0 - (height / 2))
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .text('Cost $');

  svg.append("text")
    .attr("transform", "translate(" + (width/2) + " ," + (height + margin.bottom - 15 ) + ")")
    .style("text-anchor", "middle")
    .text("Minutes");

  svg.append("path")
    .datum(data) // 10. Binds data to the line
    .attr("class", "lineStartup") // Assign a class for styling
    .attr("data-legend", 'SWRTC Startup')
    .attr("d", lineStartup); // 11. Calls the line generator
  svg.append("path")
    .datum(data) // 10. Binds data to the line
    .attr("class", "linePro") // Assign a class for styling
    .attr("data-legend", 'SWRTC Pro')
    .attr("d", linePro); // 11. Calls the line generator
  svg.append("path")
    .datum(data) // 10. Binds data to the line
    .attr("class", "linePremium") // Assign a class for styling
    .attr("data-legend", 'SWRTC Premium')
    .attr("d", linePremium); // 11. Calls the line generator
  if (options.competition) {
    svg.append("path")
      .datum(data) // 10. Binds data to the line
      .attr("class", "lineTwilio") // Assign a class for styling
      .attr("data-legend", 'Twilio')
      .attr("d", lineTwilio); // 11. Calls the line generator
    svg.append("path")
      .datum(data)
      .attr("data-legend", 'Tokbox')
      .attr("class", "lineTokbox")
      .attr("d", lineTokbox);
  }

  const legend = (g) => {
    const items = {};
    //const svg = d3.select(g.property("nearestViewportElement"));
    const legendPadding = parseInt(g.attr("data-style-padding")) || 5;

    svg.selectAll("[data-legend]").each(function() {
      var self = d3.select(this)
      items[self.attr("data-legend")] = {
        pos : self.attr("data-legend-pos") || this.getBBox().y,
        color : self.attr("data-legend-color") != undefined ? self.attr("data-legend-color") : self.style("fill") != 'none' ? self.style("fill") : self.style("stroke")
      }
    });

    const x = d3.entries(items);
    const vitems = d3.entries(items).sort(function(a,b) { return a.value.pos-b.value.pos})

    svg.selectAll('g.labels')
      .data(vitems)
      .enter()
      .append('g')
      .call((g) => {
        g.append('text')
        .text((d) => {
          return d.key;
        })
        .attr('y', (d, i) => i + 1 + 'em')
        .attr('x', 40);
        g.append('circle')
        .attr('r', 5)
        .attr('fill', d => d.value.color)
        .attr('cy', (d, i) => {
          return i + 1 - .4 + 'em';
        })
        .attr('cx', 30);
      });
    return g
  }

  svg.append("g")
    .attr("class","legend")
    .attr("transform","translate(50,30)")
    .style("font-size","12px")
    .call(legend)

}
